import React from "react";
import { Aside } from "../../../AuthCognito/components/Aside";
import { DTRServiceLogo } from "../dashboard/components/DTRServiceLogo";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

export function PartnerTermsOfService({
  showAside = true,
  showBackButton = true,
}) {
  const intl = useIntl();
  const history = useHistory();

  //Warning: This is the PRODUCTION S3 BUCKET
  const documentUrl =
    "https://coristechda3c2209cf6940b7976e4d383712a5ea142526-prod.s3.eu-central-1.amazonaws.com/public/tos/Dealer_TOS_Gennaio_2025.pdf";

  const privacyPolicyUrl = "https://www.dtr-service.it/privacy-policy";

  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
        id="kt_login"
      >
        {showAside && <Aside />}

        <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
          {showBackButton && (
            <div className="container-fluid p-0 m-0">
              <button
                type="button"
                onClick={() => history.goBack()}
                className="btn btn-light"
              >
                <i className="fa fa-arrow-left"></i>
                <FormattedMessage id="SERVICE.BACK" />
              </button>
            </div>
          )}

          {showAside && <DTRServiceLogo dynamic={false} />}

          <div className="row">
            <div className="col-0 col-md-1"></div>
            <div className="col-12 col-md-10">
              <h5 className="text-center">
                Termini di Utilizzo dei Servizi Dealer attraverso Portale web DTR-Service
              </h5>
              <br />
              <p className="text-justify">
                <span className="font-weight-bold">1. Definizioni e Allegati.</span>
                <p>Qualora riportati nel presente Contratto, i termini hanno il seguente significato:</p>
                <u>24/7/365:</u> acronimo utilizzato nel Contratto per indicare la continuità del Servizo 24 ore su 24, sette giorni alla settimana, 365 giorni all’anno;
                <br/>
                <u>Cliente:</u> il cliente può essere o il Dealer (ovvero il concessionario rivenditore) oppure il Tenant (il cliente finale che acquista o noleggia un Registratore di cassa Telematico (RT) di DTR Italy);
                <br/>
                <u>Contratto:</u> il presente scritto e tutti gli eventuali Allegati;
                <br/>
                <u>Credenziali di accesso:</u> login e password assegnate da DTR Italy al Dealer;
                <br/><u>Dealer:</u> il soggetto che compra macchine DTR seguendo il Piano Commerciale fornito annualmente direttamente da DTR o, indirettamente, da parte di un proprio distributore;
                <br/><u>DTR Italy:</u> DTR Italy s.r.l. con sede legale a Milano 20131, via G. Luosi, 14, codice fiscale e Partita IVA n. 10244350962, indirizzo PEC: <a href="mailto:dtritaly@pec.it">dtritaly@pec.it</a>, titolare e fornitore dei Servizi attraverso il Portale DTR-Service;
                <br/><u>Parti:</u> DTR Italy e il Dealer;
                <br/><u>Piano Commerciale:</u> il documento, redatto da DTR all’inizio di ogni anno solare e trasmesso ai Dealer, che indica gli obbiettivi dell’anno, il Codice Etico Comportamentale di DTR, le politiche commerciali dell’anno, la policy rilasci firmware, le licenze MOito IOT, le politiche finanziarie e i riferimenti, anche telefonici, dei contatti di DTR;
                <br/><u>Portale:</u> il sito web DTR-Service (<a href="https://www.dtr-service.it/" target="_blank">https://www.dtr-service.it/</a>) di DTR Italy;
                <br/><u>Prodotti:</u> apparecchiature hardware (es. RT: Registratore di cassa Telematico) commercializzati da DTR Italy;
                <br/><u>Servizi:</u> i servizi relativi ai Prodotti di DTR Italy forniti attraverso il Portale;
                <br/><u>Tenant:</u> il cliente finale che acquista o noleggia un Registratore di cassa Telematico (RT) di DTR Italy o abbia acquistato un Servizio dal Dealer di riferimento;
                <p>Gli Allegati sono parte integrante del presente Contratto.</p>
              </p>
              <br />
              <p className="text-justify">
                <span className="font-weight-bold">2. Oggetto dei servizi.</span> 
                <p>
                  DTR-Service è un Portale web dove il Dealer può acquistare licenze relativi ai Servizi per i Prodotti 
                  commercializzati da DTR Italy, secondo una differenziazione in più livelli, in modo da poter gestire meglio 
                  i propri Tenant, ivi compresa l’assistenza post-vendita.
                </p>
                <p>
                  Attraverso questo portale il Dealer entra ufficialmente a far parte della "COMMUNITY DTR”, ovvero un 
                  network di Aziende Dealer che insieme a DTR Italy condividono valori e strategie di mercato atte a 
                  consolidare il brand DTR Italy sull’intero territorio nazionale.
                </p>
                <p>
                  Utilizzando il Portale, il Dealer dichiara di aver letto, compreso e accettato il presente Contratto e 
                  l'Informativa sulla privacy e di conformarsi a essi. Qualora il Dealer non accetti o non possa conformarsi 
                  al presente Contratto o all'Informativa sulla privacy, deve cessare immediatamente l’utilizzo del Portale.
                </p>
                <p>
                  Il Dealer prende atto ed accetta che il Portale e i Servizi oggetto del Contratto ed erogati attraverso il 
                  Portale, sono caratterizzati da una tecnologia in continua evoluzione, per questi motivi DTR Italy si riserva 
                  il diritto di modificare in meglio le caratteristiche tecniche ed economiche del Portale, dei Servizi e degli 
                  strumenti ad esso correlati così come DTR Italy si riserva di modificare il presente Contratto dandone 
                  evidenza sul Portale; le modifiche contrattuali entreranno in vigore nel termine indicato nell’avviso sul 
                  Portale con facoltà del Dealer di recedere e con il conseguente diritto alla eventuale restituzione della quota 
                  parte di canone per i mesi che non verranno utilizzati ove già saldati.
                </p>
              </p>
              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  3. Modalità di accesso al Portale ed erogazione dei Servizi.
                </span>{" "}
                <p>
                  L’erogazione dei Servizi, dettagliati nelle loro caratteristiche anche economiche nell’Allegato “SERVIZI”, 
                  avviene mediante il Portale al quale il Dealer deve registrarsi accettando espressamente tutte le clausole 
                  del presente Contratto e fornendo tutti i dati richiesti. Il Contratto si perfeziona pertanto solo con il 
                  completamento di tutti i passaggi previsti nella registrazione al Portale.
                </p>
                <p>
                  Una volta completata la fase della registrazione e della verifica della qualifica di Dealer, l’accesso al Portale 
                  ed ai Servizi acquistati avverrà nel più breve tempo possibile.
                </p>
                <p>
                  Per poter accedere al Portale ed utilizzare i Servizi, il Dealer deve essere dotato, a proprio onere e carico, 
                  di un accesso al World Wide Web (internet), o direttamente oppure tramite strumenti che consentono 
                  l’accesso ai contenuti situati sul Web, nonché dell’attrezzatura necessaria per la connessione ad internet.
                </p>
                <p>
                  DTR Italy non garantisce la disponibilità locale, temporale e qualitativa dell’infrastruttura internet necessaria 
                  per l’accesso al Portale o per il trasferimento dei dati e, in particolare, non garantisce che le operazioni 
                  relative ai Servizi possano essere eseguite entro un lasso temporale prestabilito.
                </p>

                <p>Compatibilità Browser:</p>
                <ul>
                  <li>Chrome dalla versione 84.0.4147 e successive</li>
                  <li>Firefox dalla versione 78.0.0 e successive</li>
                  <li>Edge dalla versione 84.0.522 e successive</li>
                  <li>Safari dalla versione 13.1 e successive</li>
                </ul>
                <p>Non è invece compatibile con Internet Explorer.</p>

                <p>DTR Italy non ha server propri ma si avvale, per fornire i Servizi, di server di terze parti.</p>

                <p>
                  DTR Italy potrà, in ogni momento, interrompere l’acceso al Portale o la prestazione dei Servizi qualora
                  ricorrano motivate ragioni di sicurezza e/o garanzia di riservatezza, dandone in questo caso
                  comunicazione al Dealer nel più breve tempo possibile.
                </p>

                <p>
                  Il Dealer riconosce e accetta che DTR Italy possa interrompere l’accesso al Portale o ai Servizi senza
                  preavviso e/o risolvere immediatamente il Contratto qualora riceva una comunicazione e/o richiesta da
                  qualsiasi Autorità competente amministrativa, arbitrale o giudiziaria.
                </p>

                <p>
                  In caso di malfunzionamento dell’infrastruttura, del Portale o dei Servizi messi a disposizione del Dealer,
                  DTR Italy adotterà ogni mezzo ragionevolmente necessario per porvi rimedio nel minor tempo possibile,
                  salvo i casi in cui i guasti siano dovuti a fatti non direttamente imputabili a DTR Italy. In quest'ultimo caso
                  DTR Italy provvederà ad informare immediatamente il Dealer.
                </p>
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">4. Corrispettivi, modalità di fatturazione e variazione dei corrispettivi.</span>{" "}
                <p>Il Dealer in fase di registrazione al Portale sceglie uno delle seguenti tipologie di Profili, meglio descritti nell’Allegato CANONI:</p>
                <ul>
                <li>
                  <p>
                    a. DEALER SILVER: con un accesso supervisore e con diritto ad acquistare tutte le licenze
                    nell’arco del suo status di SILVER al prezzo di listino riservato ai Dealer facenti parte della
                    Community DTR.
                  </p>
                </li>

                <li>
                  <p>
                    b. DEALER GOLD: con un accesso supervisore e due accessi dedicati ai propri tecnici e con
                    diritto di avvalersi di uno sconto del 10% sul listino riservato ai Dealer facenti parte della
                    Community DTR su tutte le licenze acquistate nell’arco del suo status di GOLD e fino alla
                    scadenza delle licenze stesse; i rinnovi delle licenze verranno regolamentati in funzione dello
                    stato di registrazione del Dealer al momento della richiesta.
                  </p>
                </li>
                <li>
                  <p>
                    c. DEALER PLATINUM: con un accesso supervisore e cinque accessi dedicati ai propri tecnici,
                    con possibilità di esportare i file di log in formato csv contenenti statistiche ed informazioni
                    relative alle attività svolte dai propri tecnici sugli RT dei Tenant assistiti, e con diritto di
                    avvalersi di uno sconto del 20% sul listino riservato ai Dealer facenti parte della Community
                    DTR su tutte le licenze acquistate nell’arco del suo status di PLATINUM fino alla scadenza
                    delle licenze stesse; i rinnovi delle licenze saranno regolamentati in funzione dello stato di
                    registrazione del Dealer al momento della richiesta.
                  </p>
                </li>
                <li>
                  <p>
                    d. DEALER DIAMOND: con un accesso supervisore e dieci accessi dedicati ai propri tecnici, con
                    possibilità di esportare i file di log in formato csv contenenti statistiche ed informazioni relative
                    alle attività svolte dai propri tecnici sugli RT dei Tenant assistiti, e con diritto di avvalersi di
                    uno sconto del 20% sul listino riservato ai Dealer facenti parte della Community DTR su tutte
                    le licenze acquistate nell’arco del suo status di DIAMOND fino alla scadenza delle licenze
                    stesse; i rinnovi delle licenze saranno regolamentati in funzione dello stato di registrazione del
                    Dealer al momento della richiesta.
                  </p>
                </li>
                </ul>
                <p>
                  Il DEALER a seguito della registrazione sceglie uno dei Profili sopra indicati (SILVER, GOLD,
                  PLATINUM o DIAMOND) e prende atto di NON poterlo cambiare se non una sola volta all’interno
                  dei 12 mesi; questa variazione può essere fatta unicamente acquistando però un Profilo di livello
                  superiore.
                </p>
                <p>
                  Il primo anno di attivazione, ai Profili GOLD, PLATINUM e DIAMOND per i quali è previsto il
                  pagamento di un corrispettivo, l’importo verrà fatturato secondo le modalità e i tempi indicati
                  nell’Allegato CANONI; analogamente le Parti fanno riferimento a tale Allegato per le modalità di
                  rinnovo previsto per gli anni successivi, fatto salvo condizioni diverse dettate da pendenze
                  amministrative o stato di insolvenza.
                </p>
                <p>
                  DTR Italy si riserva di cambiare l’entità degli importi dei canoni indicati nell’Allegato CANONI,
                  comunicandolo al Dealer, al massimo entro il 30 novembre dell’anno, mediante pubblicazione sul
                  Portale o mediante invio di una email all’indirizzo indicato dal Dealer in fase di registrazione al Portale.
                </p>
                <p>
                  Il Dealer, attraverso il Portale, potrà acquistare i Servizi dettagliati nelle loro caratteristiche e condizioni
                  anche economiche nell’Allegato “SERVIZI”; l’importo dei Servizi acquistati verrà quindi fatturato
                  secondo le modalità e i tempi indicati nel predetto Allegato; analogamente le Parti fanno riferimento a
                  tale Allegato per le modalità di rinnovo previsto per gli anni successivi, fatto salvo condizioni diverse
                  dettate da pendenze amministrative o stato di insolvenza.
                </p>
                <p>
                  DTR Italy si riserva di cambiare l’entità degli importi indicati nell’Allegato CANONI, comunicandolo al
                  Dealer, con un preavviso di trenta giorni prima, mediante pubblicazione sul Portale o mediante invio
                  di una email all’indirizzo indicato dal Dealer in fase di registrazione al Portale.
                </p>
                <p>
                  Il Dealer riconosce e accetta che DTR Italy possa sospendere o interrompere l’accesso al Portale o
                  ai Servizi senza preavviso nel caso di mancato pagamento di una sola quota del canone annuo o dei
                  Servizi; in tale caso DTR Italy avrà il diritto di procedere secondo le modalità previste nell’articolo
                  “Risoluzione e Diffida”.
                </p>
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">5. Durata del contratto.</span>{" "}
                <p>
                  Il contratto ha durata sino al 31 dicembre dell’anno in corso e si rinnova
                  automaticamente sulla base tipologia di Servizi in essere di anno in anno unicamente ove venga pagato
                  il canone annuo previsto.
                </p>
                <p>
                  Per il Dealer non soggetto ad alcun canone, il contratto si rinnova automaticamente di anno in anno salvo
                  disdetta da comunicare da una delle Parti entro 30 (trenta) giorni prima mediante raccomandata a.r. o
                  mediante PEC.
                </p>
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">6. Diritti ed Obblighi del Dealer. </span>{" "}
                <p>
                  Il Dealer ha diritto di utilizzare il Portale e i Servizi 24/7/365 secondo quanto indicato nel presente Contratto sino a quando rivesta la qualifica di Dealer ovvero sino a quanto rispetta quanto indicato nel Piano Commerciale annualmente fornito da DTR Italy o, indirettamente, fornito da parte di un proprio distributore.
                </p>
                <p>
                  Il Dealer prende atto che in qualsiasi caso di violazione o inadempimento imputabile a DTR Italy, la stessa risponde nei limiti e nei termini indicati nel presente Contratto.
                </p>
                <p>
                  Il Dealer garantisce che i dati e le informazioni trasmesse a DTR Italy in fase di registrazione al Portale e ai Servizi ai fini della conclusione del Contratto sono veritiere, corrette e tali da consentire la sua identificazione, e si impegna a comunicare a DTR Italy ogni variazione dei medesimi. Qualora il Dealer, al momento della registrazione abbia, anche mediante l’utilizzo di documenti personali e/o societari non veri, celato la propria reale identità o dichiarato falsamente di essere altro soggetto, o comunque agito in modo tale da compromettere il processo di identificazione, egli prende atto ed accetta che sarà ritenuto, anche penalmente, responsabile per le dichiarazioni mendaci e/o l’utilizzo di falsa documentazione e sarà altresì considerato esclusivamente responsabile di tutti i danni subiti e subendi da DTR Italy e/o da terzi, assumendo sin da ora l’obbligo di manlevare e mantenere indenne DTR Italy da ogni eventuale pretesa, azione e/o richiesta di indennizzo o risarcimento danni che dovesse essere avanzata da chiunque nei loro confronti.
                </p>
                <p>
                  Le credenziali di autenticazione al Portale e ai Servizi non possono, in alcun modo, essere cedute o utilizzate da terzi senza il permesso e la preventiva autorizzazione espressa di DTR Italy. Qualora il Dealer intendesse utilizzare i Servizi per eventuali società controllate, in sede di accettazione della proposta, dovrà fornire a DTR Italy un elenco completo di tutte le società controllate che saranno autorizzate ad avvalersi di tali credenziali, con l’indicazione dei dati utili ai fini della corretta individuazione delle stesse completa di dichiarazione di accettazione i presenti Termini di utilizzo dei Servizi attraverso il Portale, debitamente sottoscritte dal legale rappresentante di ciascuna società controllata.
                </p>
                <p>
                  Il Dealer si impegna ad utilizzare il Portale e i Servizi nel rispetto delle norme vigenti, perciò evitando tassativamente di:
                  <b> a) </b>Compire attività illecite tramite il Portale o i Servizi;
                  <b> b) </b>Compire azioni che discreditino l’immagine di DTR Italy;
                  <b> c) </b>Compire azioni che possano arrecare danni materiali o immateriali ad altri soggetti di qualsiasi natura;
                  <b> d) </b>Tentare di sondare, esaminare, penetrare o testare la vulnerabilità del Portale o delle relative procedure di autenticazione o dei Servizi, sia con tecniche passive sia invasive, senza l’espresso consenso scritto di DTR Italy;
                  <b> e) </b>Utilizzare il Portale o i Servizi di DTR Italy per violare, contravvenire o far contravvenire le leggi vigenti dello Stato italiano o in qualsiasi altro Stato.
                </p>
                <p>
                  Il Dealer sarà il solo responsabile di qualsiasi utilizzo del Portale e dei Servizi e dell’infrastruttura da parte dei propri collaboratori o da parte di qualsiasi persona alla quale il Dealer stesso abbia fornito una o più chiavi di accesso che permettano di accedere al Portale e ai Servizi o ad una parte dello stesso. Il Dealer sarà altresì esclusivamente responsabile di qualsiasi conseguenza connessa allo smarrimento della propria chiave di accesso.
                </p>
                <p>
                  Il Dealer si obbliga a fornire al Tenant, nell’ambito del contratto da stipulare con questo, tutte le informazioni inerenti ai Servizi che gli fornisce, ivi compreso l’indicazione di tutte le informazioni e dati che vengono trasmessi in automatico ai server di DTR Italy dagli RT in possesso del Tenant stesso. Il Dealer si obbliga a manlevare totalmente DTR Italy da qualsiasi richiesta risarcitoria del Tenant inerente all’utilizzo dei Servizi.
                </p>
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  7. Rapporti tra Dealer e tra questi e DTR Italy.
                </span>{" "}
                <p>
                  I Dealer DTR non possono proporre accessori, ricambi o consumabili, né tantomeno effettuare servizi post-vendita a Tenant che hanno acquistato prodotti da altri Dealer DTR se non previo assenso di DTR ITALY o direttamente dal Dealer che ha attivato il RT ed eventuali servizi annessi al Tenant (c.d. divieto di “concorrenza interna”). La salvaguardia della continuità di rapporto con i propri Tenant ed il mantenimento di standard elevati di servizio sono elementi cardine alla base del successo e della soddisfazione di tutta la filiera distributiva. Questo principio di divieto di “concorrenza interna” diventa quindi fondamentale per la salvaguardia dei Dealer DTR, garantendo così anche i Tenant nel mantenimento delle programmazioni iniziali del registratore di cassa e dei servizi pattuiti in fase di acquisto, tutelando altresì il Dealer stesso da eventuali “insoluti ingiustificati”, frodi o raggiri.
                </p>
                <p>
                  I Dealer nel proporre e nel fornire i Servizi ai Tenant devono condividere preventivamente con la Direzione Commerciale DTR Italy ogni attività promozionale veicolata mediante l’utilizzo di qualsiasi strumento di marketing (ad es. Social, Mailing, volantinaggio o MassMedia), evitando di indicare prezzi fuorvianti o non chiaramente condivisi con DTR Italy ed indicando sempre e comunque un periodo di inizio e di fine della promozione stessa.
                </p>
                <p>
                  Il Dealer accetta di impegnarsi alla collaborazione reciproca con gli altri Dealer, geograficamente limitrofi, avviando così un rapporto virtuoso di interscambio di informazioni finalizzate alla miglior proposizione dei servizi e dei prodotti DTR sul territorio. Questa pattuizione contrattuale non potrà, peraltro, pregiudicare la facoltà di ciascun Dealer di proporre il miglior servizio di post-vendita a vantaggio del cliente finale (Tenant) nel rispetto della libera concorrenza e della soddisfazione del cliente stesso oltre che dell’immagine del brand DTR.
                </p>
                <p>
                  A tutela dei Dealer, DTR ITALY si riserva la facoltà di intervenire sui Dealer che violeranno questo impegno contrattuale contestando la violazione di questa previsione e ponendovi rimedio ove possibile (cancellando, salvo richiesta diversa del Tenant, ad es. la matricola di un RT di un Tenant dal profilo del Dealer ed assegnandola al Dealer di riferimento); nei casi più gravi o di reiterazione della violazione di questo impegno, DTR Italy si riserva il diritto di risolvere il presente contratto con le modalità indicate nell’articolo Risoluzione e Diffida e quindi facendo venire meno la sua qualifica di Dealer di DTR Italy.
                </p>
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  8. Obblighi e Limiti di Responsabilità di DTR Italy.
                </span>{" "}
                <p>
                  Gli obblighi e le responsabilità di DTR Italy verso il Dealer sono esclusivamente quelli definiti dal Contratto, pertanto in qualsiasi caso di violazione o inadempimento imputabile a DTR Italy, lo stesso non risponde per un importo superiore al canone dell’anno versato dal Dealer che sia stato interessato dall’evento dannoso.
                </p>
                <p>
                  Il Dealer prende atto ed accetta che DTR Italy non risponda in nessun modo di alcun danno, diretto o indiretto, di qualsiasi natura o specie subito dal Dealer stesso e/o da Terzi, in conseguenza dell’utilizzo del Portale o dei Servizi.
                </p>
                <p>
                  DTR Italy si impegna ad assicurare la migliore funzionalità del Portale e dei Servizi, ma non assume alcuna responsabilità sia verso i propri Clienti sia verso Terzi per ritardi, cattivo funzionamento, sospensione e/o interruzione nell'erogazione del Servizio o al Portale determinati da cause ad essa non imputabili, quali a titolo esemplificativo e non esaustivo:
                  <b> a) </b>caso fortuito, eventi catastrofici o di forza maggiore;
                  <b> b) </b>fatto del terzo, anche fornitore di DTR Italy;
                  <b> c) </b>malfunzionamento o non conformità degli apparecchi di connessione di cui il Cliente si sia dotato o comunque di quelli dal medesimo utilizzati;
                  <b> d) </b>manomissione o interventi sui Servizi o sulle apparecchiature eseguiti dal Cliente o da parte di soggetti Terzi non autorizzati da DTR Italy;
                  <b> e) </b>guasti e malfunzionamenti dell’hardware e dei software, siano essi di proprietà di DTR Italy e/o dei suoi fornitori.
                </p>
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">9. Diritti di proprietà intellettuale. </span>
                <p>
                  Il Dealer è tenuto ad utilizzare il Portale e i Servizi nel rispetto dei diritti
                  di proprietà intellettuale e/o industriale di DTR Italy.
                </p>
                <p>
                  Il Portale e i Servizi come qualsiasi altro diritto di autore o altro diritto di proprietà intellettuale ad essi
                  inerenti sono di proprietà esclusiva di DTR Italy e/o dei suoi danti causa, pertanto il Dealer non acquista
                  nessun diritto o titolo al riguardo ed è tenuto all’utilizzo degli stessi soltanto nel periodo di vigenza
                  contrattuale.
                </p>
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  10. Assistenza e Manutenzione.
                </span>{" "}
                <p>
                  In caso di quesiti riguardanti il Portale, i Servizi o il presente contratto il
                  Dealer può inviare una email all’indirizzo: <a href="mailto:portale@dtr-italy.com">portale@dtr-italy.com</a>.
                </p>
                <p>
                  DTR Italy si riserva la facoltà di sospendere o interrompere l’accesso al Portale o l’erogazione dei Servizi
                  per procedere ad interventi tecnici di manutenzione. In tal caso sarà data comunicazione al Dealer o
                  attraverso un avviso sul Portale o a mezzo l’email indicata in fase di registrazione, con un preavviso di 7
                  (sette) giorni; detta comunicazione indicherà altresì le tempistiche del ripristino.
                </p>
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">11. Sospensione dei Servizi. </span>{" "}
                <p>
                  Il Dealer accetta che DTR Italy, a sua discrezione e senza che l'esercizio di tale facoltà possa essergli contestata come inadempimento o violazione del Contratto, si riservi la facoltà di sospendere o interrompere l’accesso al Portale o ai Servizi, anche senza alcun preavviso nel caso in cui:
                  <b> a) </b>il Dealer si renda inadempiente o violi anche una soltanto delle disposizioni contenute nel Contratto;
                  <b> b) </b>il Dealer ometta di riscontrare, in tutto o in parte, le richieste di DTR Italy e comunque il suo comportamento sia tale da ingenerare il fondato e ragionevole timore che egli si renda inadempiente al Contratto o responsabile di una o più violazioni alle sue disposizioni;
                  <b> c) </b>vi siano fondate ragioni per ritenere che il Portale o i Servizi siano utilizzati da Terzi non autorizzati;
                  <b> d) </b>si verifichino casi di forza maggiore o circostanze che, ad insindacabile giudizio di DTR Italy, impongano di eseguire interventi di emergenza o relativi alla risoluzione di problemi di sicurezza, pericolo per l’intera rete e/o per persone o cose; in tal caso, i Servizi saranno ripristinati quando DTR Italy, a sua discrezione, abbia valutato che siano state effettivamente rimosse o eliminate le cause che avevano determinato la sua sospensione/interruzione;
                  <b> e) </b>il Dealer si trovi coinvolto, a qualsiasi titolo, in una qualsiasi controversia giudiziale o anche stragiudiziale di natura civile, penale o amministrativa e comunque nel caso in cui detta controversia abbia ad oggetto l’utilizzo del Portale o dei Servizi oggetto del presente Contratto;
                  <b> f) </b>qualora ricorrano motivate ragioni di sicurezza e/o garanzia di riservatezza;
                  <b> g) </b>qualora il Dealer utilizzi il Portale o i Servizi a scopi illeciti, inclusa la violazione di leggi sulla proprietà intellettuale ed industriale, sui diritti d’autore, sul copyright o di altri diritti di proprietà di terze parti, o in modo illegale o per la creazione o distribuzione di contenuti illegali oppure violando norme vigenti nello Stato italiano o in qualsiasi altro Stato.
                </p>
                <p>
                  In qualsiasi caso di sospensione dell’accesso al Portale o ai Servizi imputabile al Dealer resta impregiudicata l’eventuale azione di DTR Italy per il risarcimento del danno. Durante la detta sospensione, a qualsiasi causa dovuta, il Dealer non potrà avere accesso a dati e/o informazioni e/o contenuti dal medesimo immessi e/o trattati mediante il Portale o i Servizi. 
                </p>
                <p>
                  Resta inteso che in tali casi, DTR Italy non risponderà di alcuna perdita, danno o lesione subiti e/o subendi dal Dealer e/o da Terzi, siano essi diretti o indiretti, prevedibili o imprevedibili, tra i quali a titolo esemplificativo e non esaustivo: perdite economiche/finanziarie, di affari, di ricavi e di utili e/o di avviamento commerciale; 
                  Il Dealer, pertanto, prende atto ed accetta che nulla avrà da pretendere da DTR Italy a titolo di risarcimento, indennizzo, rimborso o ad altro titolo.
                </p>
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">12. Cessione del Contratto.</span>{" "}
                <p>
                  Il presente Contratto e tutti i relativi diritti non potranno essere ceduti o dati in
                  sublicenza a terzi o, comunque, concessi in uso a terzi sia a titolo gratuito, sia a titolo oneroso, senza il
                  preventivo consenso scritto di DTR Italy.
                </p>
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">13. Risoluzione e Diffida.</span>{" "}
                <p>
                  Nel caso in cui una delle due Parti fosse inadempiente ad uno qualsiasi degli
                  obblighi derivanti dal presente Contratto, l’altra Parte avrà facoltà di risolverlo, ai sensi e per gli effetti
                  dell’art. 1454 c.c., inviando alla Parte inadempiente una contestazione scritta, a mezzo raccomandata a.r.
                  o posta elettronica certificata, recante l’espressa intimazione ad adempiere entro il termine perentorio ed
                  essenziale di quindici giorni dal ricevimento della stessa. Decorso detto termine senza che vi sia stato
                  adempimento, il Contratto si intenderà risolto di diritto, fermo restando il diritto della Parte non
                  inadempiente al risarcimento del danno.
                </p>
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">14. Effetti della cessazione del Contratto.</span>{" "}
                <p>
                  Salvo il mancato pagamento in tutto o in parte del canone annuo
                  o dei Servizi per cui si applica l’ultimo paragrafo dell’4 del Contratto, in caso di cessazione del presente
                  Contratto per qualsivoglia ragione o motivo, DTR Italy bloccherà qualsiasi nuova attività sul Portale del
                  Dealer il quale potrà utilizzare sino a scadenza annuale unicamente i Servizi attivati prima della
                  comunicazione di cessazione del Contratto.
                </p>
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">15. Trattamento dei dati e Riservatezza. </span>{" "}
                <p>
                  DTR Italy dichiara al Dealer che i dati personali sono trattati nel
                  rispetto della normativa vigente sulla protezione dei dati personali e che sul Portale è disponibile
                  l’Informativa in conformità alla normativa sul trattamento dei dati personali.
                  Le Parti si obbligano reciprocamente a mantenere riservato qualsiasi tipo di informazione relativo alle
                  reciproche attività e/o alle modalità di lavoro e/o alle soluzioni adottate di cui siano venute a conoscenza
                  in dipendenza del presente contratto.
                </p>
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">16. Clausole finali.</span>{" "}
                <p>
                  Il presente Contratto esaurisce la disciplina dei diritti e degli obblighi delle Parti per quanto
                  riguarda l'Oggetto del Contratto stesso; è da intendersi annullata e privata di effetto ogni eventuale
                  precedente e diversa pattuizione scritta e/o orale fra le Parti in merito all'Oggetto del presente Contratto.
                </p>
                <p>
                  L’eventuale nullità di una o più clausole del presente Contratto non incide sulla validità del presente
                  accordo nel suo complesso e di conseguenza le parti concordano che il Giudice o qualsiasi terzo dovrà
                  in ogni caso cercare di mantenere l'efficacia degli accordi intercorsi tra le Parti, come precisati nel
                  presente Contratto e le altre disposizioni del contratto rimarranno pienamente valide ed efficaci a tutti gli
                  effetti.
                </p>
                <p>
                  Il mancato esercizio da parte di uno dei contraenti di un proprio diritto previsto dalla legge o dal presente
                  Contratto non costituisce in alcun caso rinuncia al diritto medesimo.
                </p>
                <p>
                  I titoli degli articoli del presente Contratto sono indicati solamente ai fini di prontezza di riferimento e non
                  hanno alcun effetto (vincolante) giuridico, così come i termini con la lettera maiuscola utilizzati al singolare
                  hanno lo stesso significato dei termini con la lettera maiuscola utilizzati al plurale e viceversa.
                </p>
                <p>
                  Tutte le comunicazioni la cui esecuzione è contemplata nel presente Contratto, o che comunque devono
                  essere effettuate in relazione al presente contratto, dovranno effettuarsi per raccomandata con avviso di
                  ricevimento o posta elettronica certificata e si intenderanno effettuate il giorno dell'invio per il mittente ed
                  il giorno del ricevimento per il destinatario.
                </p>
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">17. Legge applicabile e Foro competente</span>{" "}
                <p>
                  Il presente contratto è regolato, in via residuale e per tutto quanto
                  in esso non previsto, dalla normativa vigente in Italia ed è sottoposto alla giurisdizione italiana.
                </p>
                <p>
                  Il Foro competente per qualsiasi controversia dovesse insorgere a causa ed in conseguenza di tale
                  contratto è, in via esclusiva, quello di Milano.
                </p>
              </p>

              <br />
              <p>Versione aggiornata a gennaio 2025.</p>

              <br />
              <p>
                <a href={documentUrl} download target="_blank">
                  SCARICA IL DOCUMENTO
                </a>
              </p>
            </div>
            <div className="col-0 col-md-1"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
