export const listReceipts = /* GraphQL */ `
  query ListReceipts(
    $filter: ModelReceiptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReceipts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        ecrDatetime
        moitoIotDatetime
        zMoitoIot
        zEcr
        number
        amount
        posted
        type
        description
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          extensions
          createdAt
          updatedAt
          tenantUser {
            items {
              id
              userId
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
          mobile
          email
          zipCode
          county
          municipality
          address
          country
        }
        retailStore {
          id
          tenantId
          user {
            items {
              id
              userId
              retailStoreId
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
          name
          extensions
          deleted
          partnerId
          createdAt
          updatedAt
          tenant {
            id
            enabled
            logo
            shortCode
            isPartner
            partnershipStartDate
            isServiceManager
            nextItemSorting
            nextItemGroupSorting
            defaultPartnerCode
            companyName
            companyVat
            firstUserId
            firstRetailStoreId
            userCount
            moitoIotCount
            retailStoreCount
            cashRegisterCount
            rtUtilityInactive
            currentRtUtilityCategory
            partnerText
            partnerTosAccepted
            privacyPolicyAcceptedDate
            archismallUsername
            archismallPassword
            archismallActiveInvoicesLastDatetime
            archismallPassiveInvoicesLastDatetime
            extensions
            createdAt
            updatedAt
            tenantUser {
              nextToken
            }
            mobile
            email
            zipCode
            county
            municipality
            address
            country
          }
          partner {
            id
            enabled
            logo
            shortCode
            isPartner
            partnershipStartDate
            isServiceManager
            nextItemSorting
            nextItemGroupSorting
            defaultPartnerCode
            companyName
            companyVat
            firstUserId
            firstRetailStoreId
            userCount
            moitoIotCount
            retailStoreCount
            cashRegisterCount
            rtUtilityInactive
            currentRtUtilityCategory
            partnerText
            partnerTosAccepted
            privacyPolicyAcceptedDate
            archismallUsername
            archismallPassword
            archismallActiveInvoicesLastDatetime
            archismallPassiveInvoicesLastDatetime
            extensions
            createdAt
            updatedAt
            tenantUser {
              nextToken
            }
            mobile
            email
            zipCode
            county
            municipality
            address
            country
          }
          retailStoreLicense {
            items {
              id
              retailStoreId
              licenseId
              partnerId
              tenantId
              validFrom
              validTo
              assignedToUser
              activatedAt
              number
              electronicCashRegisterId
              moitoIotCode
              moitoIotId
              level
              isFree
              orderConfirmationNotified
              createdAt
              updatedAt
            }
            nextToken
          }
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          usersCount
          createdAt
          updatedAt
          tenant {
            id
            enabled
            logo
            shortCode
            isPartner
            partnershipStartDate
            isServiceManager
            nextItemSorting
            nextItemGroupSorting
            defaultPartnerCode
            companyName
            companyVat
            firstUserId
            firstRetailStoreId
            userCount
            moitoIotCount
            retailStoreCount
            cashRegisterCount
            rtUtilityInactive
            currentRtUtilityCategory
            partnerText
            partnerTosAccepted
            privacyPolicyAcceptedDate
            archismallUsername
            archismallPassword
            archismallActiveInvoicesLastDatetime
            archismallPassiveInvoicesLastDatetime
            extensions
            createdAt
            updatedAt
            tenantUser {
              nextToken
            }
            mobile
            email
            zipCode
            county
            municipality
            address
            country
          }
          partner {
            id
            enabled
            logo
            shortCode
            isPartner
            partnershipStartDate
            isServiceManager
            nextItemSorting
            nextItemGroupSorting
            defaultPartnerCode
            companyName
            companyVat
            firstUserId
            firstRetailStoreId
            userCount
            moitoIotCount
            retailStoreCount
            cashRegisterCount
            rtUtilityInactive
            currentRtUtilityCategory
            partnerText
            partnerTosAccepted
            privacyPolicyAcceptedDate
            archismallUsername
            archismallPassword
            archismallActiveInvoicesLastDatetime
            archismallPassiveInvoicesLastDatetime
            extensions
            createdAt
            updatedAt
            tenantUser {
              nextToken
            }
            mobile
            email
            zipCode
            county
            municipality
            address
            country
          }
          retailStore {
            id
            tenantId
            user {
              nextToken
            }
            name
            extensions
            deleted
            partnerId
            createdAt
            updatedAt
            tenant {
              id
              enabled
              logo
              shortCode
              isPartner
              partnershipStartDate
              isServiceManager
              nextItemSorting
              nextItemGroupSorting
              defaultPartnerCode
              companyName
              companyVat
              firstUserId
              firstRetailStoreId
              userCount
              moitoIotCount
              retailStoreCount
              cashRegisterCount
              rtUtilityInactive
              currentRtUtilityCategory
              partnerText
              partnerTosAccepted
              privacyPolicyAcceptedDate
              archismallUsername
              archismallPassword
              archismallActiveInvoicesLastDatetime
              archismallPassiveInvoicesLastDatetime
              extensions
              createdAt
              updatedAt
            }
            partner {
              id
              enabled
              logo
              shortCode
              isPartner
              partnershipStartDate
              isServiceManager
              nextItemSorting
              nextItemGroupSorting
              defaultPartnerCode
              companyName
              companyVat
              firstUserId
              firstRetailStoreId
              userCount
              moitoIotCount
              retailStoreCount
              cashRegisterCount
              rtUtilityInactive
              currentRtUtilityCategory
              partnerText
              partnerTosAccepted
              privacyPolicyAcceptedDate
              archismallUsername
              archismallPassword
              archismallActiveInvoicesLastDatetime
              archismallPassiveInvoicesLastDatetime
              extensions
              createdAt
              updatedAt
            }
            retailStoreLicense {
              nextToken
            }
            zipCode
            county
            municipality
            address
            country
          }
          fixedVersion {
            id
            version
            frontendZipPath
            apkPath
            notes
            isStable
            createdAt
            updatedAt
          }
        }
        department {
          id
          tenantId
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
          tenant {
            id
            enabled
            logo
            shortCode
            isPartner
            partnershipStartDate
            isServiceManager
            nextItemSorting
            nextItemGroupSorting
            defaultPartnerCode
            companyName
            companyVat
            firstUserId
            firstRetailStoreId
            userCount
            moitoIotCount
            retailStoreCount
            cashRegisterCount
            rtUtilityInactive
            currentRtUtilityCategory
            partnerText
            partnerTosAccepted
            privacyPolicyAcceptedDate
            archismallUsername
            archismallPassword
            archismallActiveInvoicesLastDatetime
            archismallPassiveInvoicesLastDatetime
            extensions
            createdAt
            updatedAt
            tenantUser {
              nextToken
            }
            mobile
            email
            zipCode
            county
            municipality
            address
            country
          }
          vatRate {
            id
            tenantId
            code
            description
            sorting
            rate
            extensions
            createdByModule
            deleted
            createdAt
            updatedAt
            tenant {
              id
              enabled
              logo
              shortCode
              isPartner
              partnershipStartDate
              isServiceManager
              nextItemSorting
              nextItemGroupSorting
              defaultPartnerCode
              companyName
              companyVat
              firstUserId
              firstRetailStoreId
              userCount
              moitoIotCount
              retailStoreCount
              cashRegisterCount
              rtUtilityInactive
              currentRtUtilityCategory
              partnerText
              partnerTosAccepted
              privacyPolicyAcceptedDate
              archismallUsername
              archismallPassword
              archismallActiveInvoicesLastDatetime
              archismallPassiveInvoicesLastDatetime
              extensions
              createdAt
              updatedAt
            }
          }
        }
        receiptPayments {
          items {
            id
            tenantId
            receiptId
            paymentId
            amount
            sorting
            description
            createdAt
            updatedAt
            tenant {
              id
              enabled
              logo
              shortCode
              isPartner
              partnershipStartDate
              isServiceManager
              nextItemSorting
              nextItemGroupSorting
              defaultPartnerCode
              companyName
              companyVat
              firstUserId
              firstRetailStoreId
              userCount
              moitoIotCount
              retailStoreCount
              cashRegisterCount
              rtUtilityInactive
              currentRtUtilityCategory
              partnerText
              partnerTosAccepted
              privacyPolicyAcceptedDate
              archismallUsername
              archismallPassword
              archismallActiveInvoicesLastDatetime
              archismallPassiveInvoicesLastDatetime
              extensions
              createdAt
              updatedAt
            }
            receipt {
              id
              tenantId
              retailStoreId
              moitoIotId
              moitoIotNumber
              ecrNumber
              moitoIotDatetime
              zMoitoIot
              zEcr
              number
              amount
              posted
              type
              description
              extensions
              deleted
              createdAt
              updatedAt
            }
            payment {
              id
              tenantId
              sorting
              name
              color
              enabled
              presetAmount
              automaticDiscount
              allowLeftovers
              allowIncoming
              allowWithdrawals
              allowComment
              allowZero
              automaticOpening
              extensions
              deleted
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        lines {
          items {
            id
            tenantId
            receiptId
            departmentId
            item {
              id
              tenantId
              name
              description
              images
              atorderType
              sorting
              vatRate
              vatCode
              extensions
              createdByModule
              deleted
              qtyOnHand
              enabledRetailStoreIds
              loyaltyPoints
              createdAt
              updatedAt
            }
            sorting
            description
            comment
            price
            quantity
            total
            loyaltyPoints
            createdAt
            updatedAt
            tenant {
              id
              enabled
              logo
              shortCode
              isPartner
              partnershipStartDate
              isServiceManager
              nextItemSorting
              nextItemGroupSorting
              defaultPartnerCode
              companyName
              companyVat
              firstUserId
              firstRetailStoreId
              userCount
              moitoIotCount
              retailStoreCount
              cashRegisterCount
              rtUtilityInactive
              currentRtUtilityCategory
              partnerText
              partnerTosAccepted
              privacyPolicyAcceptedDate
              archismallUsername
              archismallPassword
              archismallActiveInvoicesLastDatetime
              archismallPassiveInvoicesLastDatetime
              extensions
              createdAt
              updatedAt
            }
            item {
              id
              tenantId
              name
              description
              images
              atorderType
              sorting
              vatRate
              vatCode
              extensions
              createdByModule
              deleted
              enabledRetailStoreIds
              loyaltyPoints
              createdAt
              updatedAt
            }
            department {
              id
              tenantId
              description
              forFree
              sorting
              enabled
              activityCode
              extensions
              createdByModule
              deleted
              vatRate {
                id
                tenantId
                code
                description
                sorting
                rate
                extensions
                createdByModule
                deleted
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            discountSurcharge {
              id
              tenantId
              description
              value
              percValue
              discountSurcharge
              itemSubTotal
              enabled
              sorting
              deleted
              createdAt
              updatedAt
            }
            receipt {
              id
              tenantId
              retailStoreId
              moitoIotId
              moitoIotNumber
              ecrNumber
              moitoIotDatetime
              zMoitoIot
              zEcr
              number
              amount
              posted
              type
              description
              extensions
              deleted
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const receiptsByRetailStore = /* GraphQL */ `
  query ReceiptsByRetailStore(
    $retailStoreId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReceiptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    receiptsByRetailStore(
      retailStoreId: $retailStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        ecrDatetime
        moitoIotDatetime
        zMoitoIot
        zEcr
        number
        amount
        posted
        type
        description
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          companyName
          companyVat
          mobile
          email
          zipCode
          county
          municipality
          address
          country
        }
        retailStore {
          id
          tenantId
          name
          deleted
          partnerId
          createdAt
          updatedAt
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          usersCount
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
          vatRate {
            id
            tenantId
            code
            description
            sorting
            rate
            extensions
            createdByModule
            deleted
            createdAt
            updatedAt
          }
        }
        receiptPayments {
          items {
            id
            tenantId
            receiptId
            paymentId
            amount
            sorting
            description
            createdAt
            updatedAt
            receipt {
              id
              tenantId
              retailStoreId
              moitoIotId
              moitoIotNumber
              ecrNumber
              moitoIotDatetime
              zMoitoIot
              zEcr
              number
              amount
              posted
              type
              description
              extensions
              deleted
              createdAt
              updatedAt
            }
            payment {
              id
              tenantId
              sorting
              name
              color
              enabled
              presetAmount
              automaticDiscount
              allowLeftovers
              allowIncoming
              allowWithdrawals
              allowComment
              allowZero
              automaticOpening
              extensions
              deleted
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        lines {
          items {
            id
            tenantId
            receiptId
            departmentId
            item {
              id
              tenantId
              name
              description
              images
              atorderType
              sorting
              vatRate
              vatCode
              extensions
              createdByModule
              deleted
              qtyOnHand
              enabledRetailStoreIds
              loyaltyPoints
              createdAt
              updatedAt
            }
            sorting
            description
            comment
            price
            quantity
            total
            loyaltyPoints
            createdAt
            updatedAt
            department {
              id
              tenantId
              description
              forFree
              sorting
              enabled
              activityCode
              extensions
              createdByModule
              deleted
              vatRate {
                id
                tenantId
                code
                description
                sorting
                rate
                extensions
                createdByModule
                deleted
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            discountSurcharge {
              id
              tenantId
              description
              value
              percValue
              discountSurcharge
              itemSubTotal
              enabled
              sorting
              deleted
              createdAt
              updatedAt
            }
            receipt {
              id
              tenantId
              retailStoreId
              moitoIotId
              moitoIotNumber
              ecrNumber
              moitoIotDatetime
              zMoitoIot
              zEcr
              number
              amount
              posted
              type
              description
              extensions
              deleted
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const variantsByTenantSorting = /* GraphQL */ `
  query VariantsByTenantSorting(
    $tenantId: ID
    $sorting: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVariantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    variantsByTenantSorting(
      tenantId: $tenantId
      sorting: $sorting
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        description
        sorting
        extensions
        createdByModule
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          createdAt
          updatedAt
        }
        variantVariantGroups {
          items {
            id
            tenantId
            variantId
            variantGroupId
            createdAt
            updatedAt
            variant {
              id
              tenantId
              description
              sorting
              extensions
              createdByModule
              deleted
              createdAt
              updatedAt
            }
            variantGroup {
              id
              tenantId
              description
              sorting
              enabled
              color
              extensions
              createdByModule
              deleted
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        variantModifiers {
          items {
            id
            tenantId
            variantId
            modifierId
            price
            createdAt
            updatedAt
            variant {
              id
              tenantId
              description
              sorting
              extensions
              createdByModule
              deleted
              createdAt
              updatedAt
            }
            modifier {
              id
              tenantId
              description
              color
              extensions
              createdByModule
              deleted
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const receiptsByCustomer = /* GraphQL */ `
  query ReceiptsByCustomer(
    $customerId: ID
    $moitoIotDatetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReceiptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    receiptsByCustomer(
      customerId: $customerId
      moitoIotDatetime: $moitoIotDatetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        moitoIotId
        moitoIotNumber
        ecrNumber
        moitoIotDatetime
        zMoitoIot
        zEcr
        ecrDatetime
        number
        amount
        percDiscount
        valDiscount
        discountDescription
        customerId
        posted
        type
        description
        extensions
        deleted
        netAmount
        taxAmount
        payedAmount
        changeAmount
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          ecrNumber
          createdAt
          updatedAt
        }
        department {
          id
          tenantId
          color
          description
          forFree
          sorting
          enabled
          activityCode
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        receiptPayments {
          items {
            id
            tenantId
            receiptId
            paymentId
            amount
            sorting
            description
            createdAt
            updatedAt
            receipt {
              id
              tenantId
              retailStoreId
              moitoIotId
              moitoIotNumber
              ecrNumber
              moitoIotDatetime
              zMoitoIot
              zEcr
              number
              amount
              posted
              type
              description
              extensions
              deleted
              createdAt
              updatedAt
            }
            payment {
              id
              tenantId
              sorting
              name
              color
              enabled
              presetAmount
              automaticDiscount
              allowLeftovers
              allowIncoming
              allowWithdrawals
              allowComment
              allowZero
              automaticOpening
              extensions
              deleted
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        lines {
          items {
            id
            tenantId
            receiptId
            departmentId
            item {
              id
              tenantId
              name
              description
              images
              atorderType
              sorting
              vatRate
              vatCode
              extensions
              createdByModule
              deleted
              qtyOnHand
              enabledRetailStoreIds
              loyaltyPoints
              createdAt
              updatedAt
            }
            sorting
            description
            comment
            price
            quantity
            total
            loyaltyPoints
            createdAt
            updatedAt
            department {
              id
              tenantId
              description
              forFree
              sorting
              enabled
              activityCode
              extensions
              createdByModule
              deleted
              vatRate {
                id
                tenantId
                code
                description
                sorting
                rate
                extensions
                createdByModule
                deleted
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            discountSurcharge {
              id
              tenantId
              description
              value
              percValue
              discountSurcharge
              itemSubTotal
              enabled
              sorting
              deleted
              createdAt
              updatedAt
            }
            receipt {
              id
              tenantId
              retailStoreId
              moitoIotId
              moitoIotNumber
              ecrNumber
              moitoIotDatetime
              zMoitoIot
              zEcr
              number
              amount
              posted
              type
              description
              extensions
              deleted
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        calendarEvent {
          id
          tenantId
          retailStoreId
          userId
          calendarEventTypeId
          customerId
          notificationEnabled
          notificationDatetime
          notificationSent
          title
          description
          startDatetime
          endDatetime
          tags
          isAllDayEvent
          extensions
          mobile
          deleted
          operatorId
          closed
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;

export const calendarEventByRetailStore = /* GraphQL */ `
  query CalendarEventByRetailStore(
    $retailStoreId: ID
    $startDatetimeEndDatetime: ModelCalendarEventByRetailStoreCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    calendarEventByRetailStore(
      retailStoreId: $retailStoreId
      startDatetimeEndDatetime: $startDatetimeEndDatetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        userId
        calendarEventTypeId
        customerId
        notificationEnabled
        notificationDatetime
        notificationSent
        title
        description
        startDatetime
        endDatetime
        tags
        isAllDayEvent
        extensions
        mobile
        email
        deleted
        operatorId
        closed
        isPending
        address
        source
        printed
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          notes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        calendarEventType {
          id
          tenantId
          retailStoreId
          name
          color
          notificationEnabled
          notificationAnticipationMinutes
          title
          extensions
          deleted
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          name
          deleted
          sorting
          createdAt
          updatedAt
        }
        calendarEventItems {
          items {
            id
            tenantId
            retailStoreId
            itemId
            comment
            item {
              id
              tenantId
              name
              description
              images
              atorderType
              sorting
              vatRate
              vatCode
              extensions
              createdByModule
              deleted
              qtyOnHand
              qtyOnHandByStoreId
              enabledRetailStoreIds
              loyaltyPoints
              time
              image
              externalId
              createdAt
              updatedAt
            }
            qty
            calendarEventId
            calendarEventItemVariants {
              items {
                id
                tenantId
                retailStoreId
                price
                calendarEventId
                calendarEventItemId
                variantId
                modifierId
                variant {
                  id
                  tenantId
                  description
                  sorting
                  extensions
                  createdByModule
                  deleted
                  createdAt
                  updatedAt
                }
                modifier {
                  id
                  tenantId
                  description
                  color
                  extensions
                  createdByModule
                  deleted
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const itemMoitoIotLogicalPrinterByLogicalPrinter = /* GraphQL */ `
  query ItemMoitoIotLogicalPrinterByLogicalPrinter(
    $logicalPrinterId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelItemMoitoIotLogicalPrinterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemMoitoIotLogicalPrinterByLogicalPrinter(
      logicalPrinterId: $logicalPrinterId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        itemId
        logicalPrinterId
        isMain
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          partnerTosAcceptedVersion
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          blockLevel
          dailyReportMode
          createdAt
          updatedAt
        }
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          time
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          image
          createdAt
          updatedAt
          itemGroup {
            items {
              id
              tenantId
              itemId
              itemGroupId
              createdAt
              updatedAt
              item {
                id
                tenantId
                name
                description
                images
                atorderType
                sorting
                vatRate
                vatCode
                extensions
                createdByModule
                deleted
                qtyOnHand
                qtyOnHandByStoreId
                enabledRetailStoreIds
                loyaltyPoints
                createdAt
                updatedAt
              }
              itemGroup {
                id
                tenantId
                name
                description
                sorting
                extensions
                createdByModule
                atorderType
                deleted
                enabledRetailStoreIds
                color
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          priceList {
            items {
              id
              tenantId
              itemId
              priceListId
              price
              deleted
              extensions
              createdAt
              updatedAt
              item {
                id
              }
              priceList {
                id
                tenantId
                retailStoreId
                name
                extensions
                createdByModule
                sorting
                deleted
                visible
                enabled
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          department {
            id
            tenantId
            color
            description
            forFree
            sorting
            enabled
            activityCode
            extensions
            createdByModule
            deleted
            createdAt
            updatedAt
          }
          discountSurcharge {
            id
            tenantId
            description
            value
            percValue
            discountSurcharge
            itemSubTotal
            enabled
            sorting
            deleted
            createdAt
            updatedAt
          }
          variantGroups {
            items {
              id
              tenantId
              variantGroupId
              itemId
              variantGroup {
                id
                tenantId
                description
                sorting
                enabled
                color
                extensions
                createdByModule
                deleted
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          logicalPrinters {
            items {
              id
              tenantId
              retailStoreId
              itemId
              logicalPrinterId
              logicalPrinter {
                id
                tenantId
                retailStoreId
                physicalPrinterId
                name
                description
                printLogoOrder
                beepAfterOrder
                deleted
                createdAt
                updatedAt
              }
              isMain
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          isSharedTenant
          iban
          enowebMemId
          createdAt
          updatedAt
        }
        logicalPrinter {
          id
          tenantId
          retailStoreId
          physicalPrinterId
          name
          description
          printLogoOrder
          beepAfterOrder
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const itemVariantGroupsByVariantGroup = /* GraphQL */ `
  query ItemVariantGroupsByVariantGroup(
    $variantGroupId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelItemVariantGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemVariantGroupsByVariantGroup(
      variantGroupId: $variantGroupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        variantGroupId
        itemId
        createdAt
        updatedAt
        item {
          id
          tenantId
          name
          description
          images
          atorderType
          sorting
          vatRate
          time
          vatCode
          extensions
          createdByModule
          deleted
          qtyOnHand
          qtyOnHandByStoreId
          enabledRetailStoreIds
          loyaltyPoints
          image
          createdAt
          updatedAt
          itemGroup {
            items {
              id
              tenantId
              itemId
              itemGroupId
              createdAt
              updatedAt
              item {
                id
                tenantId
                name
                description
                images
                atorderType
                sorting
                vatRate
                vatCode
                extensions
                createdByModule
                deleted
                qtyOnHand
                qtyOnHandByStoreId
                enabledRetailStoreIds
                loyaltyPoints
                createdAt
                updatedAt
              }
              itemGroup {
                id
                tenantId
                name
                description
                sorting
                extensions
                createdByModule
                atorderType
                deleted
                enabledRetailStoreIds
                color
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          priceList {
            items {
              id
              tenantId
              itemId
              priceListId
              price
              deleted
              extensions
              createdAt
              updatedAt
              item {
                id
              }
              priceList {
                id
                tenantId
                retailStoreId
                name
                extensions
                createdByModule
                sorting
                deleted
                visible
                enabled
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          department {
            id
            tenantId
            color
            description
            forFree
            sorting
            enabled
            activityCode
            extensions
            createdByModule
            deleted
            createdAt
            updatedAt
          }
          discountSurcharge {
            id
            tenantId
            description
            value
            percValue
            discountSurcharge
            itemSubTotal
            enabled
            sorting
            deleted
            createdAt
            updatedAt
          }
          variantGroups {
            items {
              id
              tenantId
              variantGroupId
              itemId
              variantGroup {
                id
                tenantId
                description
                sorting
                enabled
                color
                extensions
                createdByModule
                deleted
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          logicalPrinters {
            items {
              id
              tenantId
              retailStoreId
              itemId
              logicalPrinterId
              logicalPrinter {
                id
                tenantId
                retailStoreId
                physicalPrinterId
                name
                description
                printLogoOrder
                beepAfterOrder
                deleted
                createdAt
                updatedAt
              }
              isMain
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        variantGroup {
          id
          tenantId
          description
          sorting
          enabled
          color
          extensions
          createdByModule
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;