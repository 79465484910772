import React, { useMemo } from "react";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import { GetTenant } from "../../../../app/utils";

export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true)
    };
  }, [uiService]);

  const { tenant } = useSelector(
    (state) => ({
      tenant: GetTenant(),
    }),
    shallowEqual
  );

  //Warning: This is the PRODUCTION S3 BUCKET
  const baseUrl = "https://coristechda3c2209cf6940b7976e4d383712a5ea142526-prod.s3.eu-central-1.amazonaws.com/public/tos/";
  const DealerTosPdf = baseUrl + "Dealer_TOS_Gennaio_2025.pdf";
  const DealerPrivacyPolicyPdf = baseUrl + "DEALER_PRIVACY_POLICY.pdf";
  const ServicesPdf = baseUrl + "SERVIZI.pdf";
  const FeePdf = baseUrl + "CANONI.pdf";
  const TenantTosPdf = baseUrl + "Tenant_TOS_Gennaio_2025.pdf";
  const TenantPrivacyPolicyPdf = baseUrl + "TENANT_PRIVACY_POLICY.pdf";

  return (
    <div
      className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted font-weight-bold mr-2">{today.toString()}</span> &copy;{" "}
          <Link
            to="/"
            className="text-dark-75 text-hover-primary"
          >
            DTR-Service
          </Link>
        </div>
        <div className="order-2 order-md-1 text-center text-muted">
          {tenant && (
            <>
              {tenant.isPartner ? (
                <>
                  <a href={DealerTosPdf} download target="_blank" className="text-hover-primary text-dark ">
                    Termini di servizio
                  </a>
                  <br/>
                  <Link to="/internal-privacy-policy" className="text-hover-primary text-dark ">
                    Privacy Policy
                  </Link>
                  <br/>
                  <a href={ServicesPdf} download target="_blank" className="text-hover-primary text-dark ">
                    Servizi
                  </a>
                  {"  -  "}
                  <a href={FeePdf} download target="_blank" className="text-hover-primary text-dark ">
                    Canoni
                  </a>
                </>
              ) : (
                <>
                  <a href={TenantTosPdf} download target="_blank" className="text-hover-primary text-dark ">
                    Termini di servizio
                  </a>
                  <br/>
                  <Link to="/internal-privacy-policy" className="text-hover-primary text-dark ">
                    Privacy Policy
                  </Link>
                </>
              )}
            </>
          )}
        </div>
        <div className="order-2 order-md-1">
          DTR Italy S.r.l. <br/>
          Sede Legale: Via Giuseppe Luosi 14 - 20131 Milano <br/>
          Sede Operativa: Via Lussemburgo 28 - 21013 Gallarate (VA) 
        </div>
        <div className="order-2 order-md-1">
          <img 
            src="https://www.dtr-italy.eu/wp-content/uploads/2022/09/logo_dtr-italy.svg" 
            style={{height:30}}
          />
          <br/>
          P.IVA/C.F. 10244350962 <br/>
          <span className="text-hover-primary">commerciale@dtr-italy.com</span>
        </div>
      </div>
    </div>
  );
}
